import React, { Suspense, lazy } from "react";

//  lazy
const Introduction = lazy(() => import("./introduction/Introduction"));
const Information = lazy(() => import("./information/Information"));
const Courses = lazy(() => import("./courses/Courses"));
const Methodology = lazy(() => import("./methodology/Methodology"));
const Footer = lazy(() => import("./footer/Footer"));
const Testimonials = lazy(() => import("./testimonials/Testimonials"));
/**************************
 * Main
 **************************/
export default function Main() {
  const fallMessage = (
    <div style={{ width: "100%", textAlign: "center", marginTop: "1em" }}>
      Loading.....
    </div>
  );
  return (
    <div style={{ width: "100%" }}>
      <Suspense fallback={fallMessage}>
        <Introduction />
        <Information />
        <Courses />
        <Methodology />
        <Testimonials />
        <Footer />
      </Suspense>
    </div>
  );
}
