import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Main from "./components/page-one/Main";

const Courses = lazy(() => import("./components/page-one/courses/Courses"));
const Information = lazy(
  () => import("./components/page-one/information/Information"),
);

function App() {
  
  // Disable right-click using useEffect hook
  useEffect(() => {
    const handleContextMenu = (e: any) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <>
      <Router>
        <Suspense
          fallback={
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "1em" }}
            >
              Loading.....
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Main />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
